import {generatePrivacyObject} from '@mw/privacy-link'
export async function getPrivacyLink({ countryCode, lang, linkType, appendix }) {
    const fetchData = async () =>
        await generatePrivacyObject({
            countryCode,
            lang,
            linkType,
            appendix
        })
    return fetchData()
        .then(privacyLink => privacyLink)
        .catch(error => console.error(error))
}